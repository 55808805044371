import React from 'react';
import '../css/legal.css';
import Header from '../components/header'; 
import Footer from '../components/footer'; 

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <section className="legal-container">
                <div className="terms">
                    <div className="head">
                        <h1 className="title">Privacy Policy</h1>
                        <h3 className="subtitle">Last Updated: 2 December 2021</h3>
                    </div>
                    <h4 className="title">Introduction</h4>
                    <p>By using any of BlueFoxHost (“we”, “us”) services, including, the website, discord server, billing, panel, and more, you (“client”, “you”, “your”) agree to the following policies regarding your privacy. We take privacy very seriously here at BlueFoxHost, and would like to be transparent with you the best we can. Your personal data includes information such as: discord username &amp; discriminator, email address, password, and IP Address. These policies are intended to tell you what data we collect, what we use it for, why it is important, and how you can have it removed from our databases. We may disclose your information if required by law.</p>
                    <h4 className="title">Storing of Information</h4>
                    <p>All of the information we collect is stored securely in our databases. Information regarding your IP, location, and anything else related to tracking is stored in our databases. All of your information can be deleted per your request. Here is how we keep your personal data safe: Secure password hashing, zero-trust policies, etc</p>
                    <h4 className="title">GDPR Regulations</h4>
                    <p>At BlueFoxHost, we would like you to know your GDPR (“General Data Protection Rights”) rights. Every user is in entitled to the following: The right to access, The right to rectification, The right to erasure</p>
                    <h4 className="title">COPPA</h4>
                    <p>BlueFoxHost does not knowingly collect data of any person under the age of 13. Though, we may have data collected of users under 18 years of age. If you, or someone else, believe we have data of an unknowing minor, please reach out to us.</p>
                    <h4 className="title">Communication</h4>
                    <p>We strongly believe that you have the full right of all data removal. We may contact you directly from a third party service or provider, such as; discord, email, phone call, etc. in regards to your purchased services. If you have any questions, inquiries, would like data removed, or any other reason, please reach out to us at support@bluefoxhost.com, with the subject of whatever it is related.</p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
