import Home from './pages/index'
import About from './pages/about'
import Contact from './pages/contact'
import Partners from './pages/partners'
import Privacy from './pages/privacy'
import Error from './pages/error'
import Hardware from './pages/hardware'
import Products from './pages/products'
import SLA from './pages/sla'
import Team from './pages/team'
import Terms from './pages/terms'
import Terraria from './pages/terraria'
import Minecraft from './pages/minecraft'
import Bots from './pages/bots'
import Locations from './pages/locations'
const pagesData = [
  {
    path: "",
    element: <Home />,
    title: "home"
  },
  {
    path: "company/about",
    element: <About />,
    title: "about"
  },
  {
    path: "company/team",
    element: <Team />,
    title: "team"
  },
  {
    path: "company/hardware",
    element: <Hardware />,
    title: "hardware"
  },
  {
    path: "contact",
    element: <Contact />,
    title: "contact"
  },
  {
    path: "locations",
    element: <Locations />,
    title: "locations"
  },
  {
    path: "privacy",
    element: <Privacy />,
    title: "privacy"
  },
  {
    path: "sla",
    element: <SLA />,
    title: "sla"
  },
  {
    path: "terms",
    element: <Terms />,
    title: "terms"
  },
  {
    path: "partners",
    element: <Partners />,
    title: "partners"
  },
  {
    path: "products",
    element: <Products />,
    title: "products"
  },
  {
    path: "products/minecraft",
    element: <Minecraft />,
    title: "minecraft",
  },
  {
    path: "products/terraria",
    element: <Terraria />,
    title: "terraria",
  },
  {
    path: "products/bots",
    element: <Bots />,
    title: "bots",
  },
  {
    path: "*",
    element: <Error />,
    title: "error"
  }
];
export default pagesData;