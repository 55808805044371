import { useNavigate } from "react-router-dom";

function GetStartedButton() {
    const navigate = useNavigate();

    function handleClick() {
        let selected = document.getElementById('selected')
        let value = selected.value;
    
        if(value === 'none') {
            return navigate('/products')
        }
    
        if(value === 'minecraft') {
            return navigate('/products/minecraft')
        }
    
        if(value === 'terraria') {
            return navigate('/products/terraria')
        }
    
        if(value === 'bots') {
            return navigate('/products/bots')
        }
    }

    return (
        <button id="getstarted" className="get-started" onClick={handleClick}>
            Get Started
        </button>
    );
}

export default GetStartedButton;