import React from 'react';
import Header from '../components/header'; 
import Footer from '../components/footer';
import s from '../css/company.css'; 
import JoshImage from '../images/team/joshua.png'
import WilliamImage from '../images/team/william.jpeg'
import RobbieImage from '../images/team/robbie.jpg'
import Mattimage from '../images/team/matt.png'
import CarsonImage from '../images/team/carson.png'

const Team = () => {
    return (
        <div>
            <Header />
            <section className="company">
                <p className="title">Team</p>
                <p className="subtitle">Discover the creative geniuses shaping BlueFoxHost's enchanting experiences – say hello to our remarkable team!</p>

                <div className="grid-team">
                    <div className="team-user">
                        <div className="imageDiv" style={{ "--background": `url(${JoshImage})` }}>
                            <img className="userImage" src={JoshImage} alt="" />
                        </div>

                        <div className="user-section">
                            <p className="user-name">Joshua Eubanks</p>
                            <p className="user-title">Chief Executive Officer</p>

                            <a href="mailto:joshe@bluefoxhost.com"><i className="user-promote fa-regular fa-envelope"></i></a>
                            <a target="_blank" href="https://twitter.com/josheubanks0"><i className="user-promote fa-brands fa-x-twitter"></i></a>
                            <a target="_blank" href="https://instagram.com/josheubanks0"><i className="user-promote fa-brands fa-instagram"></i></a>
                        </div>
                    </div>

                    <div className="team-user">
                        <div className="imageDiv" style={{ "--background": `url(${WilliamImage})` }}>
                            <img className="userImage" src={WilliamImage} alt="" />
                        </div>

                        <div className="user-section">
                            <p className="user-name">William</p>
                            <p className="user-title">Chief Operating Officer</p>

                            <a href="mailto:williamb@bluefoxhost.com"><i className="user-promote fa-regular fa-envelope"></i></a>
                            <a target="_blank" href="https://twitter.com/Orashgle"><i className="user-promote fa-brands fa-x-twitter"></i></a>
                        </div>
                    </div>

                    <div className="team-user">
                        <div className="imageDiv" style={{ "--background": `url(${RobbieImage})` }}>
                            <img className="userImage" src={RobbieImage} alt="" />
                        </div>

                        <div className="user-section">
                            <p className="user-name">Robbie</p>
                            <p className="user-title">Lead Support</p>

                            <a href="mailto:robbiec@bluefoxhost.com"><i className="user-promote fa-regular fa-envelope"></i></a>
                            <a target="_blank" href="https://twitter.com/_robbiecutler"><i className="user-promote fa-brands fa-x-twitter"></i></a>
                        </div>
                    </div>

                    <div className="team-user">
                        <div className="imageDiv" style={{ "--background": `url(${Mattimage})` }}>
                            <img className="userImage" src={Mattimage} alt="" />
                        </div>

                        <div className="user-section">
                            <p className="user-name">Matt</p>
                            <p className="user-title">Support</p>

                            <a href="mailto:mattt@bluefoxhost.com"><i className="user-promote fa-regular fa-envelope"></i></a>
                            <a target="_blank" href="https://twitter.com/tweak4141"><i className="user-promote fa-brands fa-x-twitter"></i></a>
                            <a target="_blank" href="https://github.com/Tweak4141"><i className="user-promote fa-brands fa-github"></i></a>
                        </div>
                    </div>

                    <div className="team-user">
                        <div className="imageDiv" style={{ "--background": `url(${CarsonImage})` }}>
                            <img className="userImage" src={CarsonImage} alt="" />
                        </div>

                        <div className="user-section">
                            <p className="user-name">Carson</p>
                            <p className="user-title">Moderator</p>

                            {/* <a href="mailto:username@bluefoxhost.com"><i className="user-promote fa-regular fa-envelope"></i></a> */}
                            <a target="_blank" href="https://twitter.com/CXgraphics1"><i className="user-promote fa-brands fa-x-twitter"></i></a>
                            <a target="_blank" href="https://github.com/CXgraphics1"><i className="user-promote fa-brands fa-github"></i></a>
                        </div>
                    </div>
                </div>

                <div className="join-discord">
                    <p className={s.small_title}>Want to say hi to our team?</p>
                    <p className="subtitle">Are you excited to get to know the faces behind the scenes? Do you have a friendly message you've been eager to share?</p>
                    <a href="https://discord.gg/bluefox">
                        <button className="btn-discord">
                            Join Discord
                        </button>
                    </a>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Team;
