const ProductBox = ({ planName, gb, features, price, href, type, icon }) => {
    let color = "";
    if (type) color = type + "-text";
    return (
        <div className="product-game-box">
            <i className={ "plan-svg fa-solid fa-" + icon }></i>
            <span className="product-plan-name">{planName}</span>
            <p className="plan-gb">{gb}</p>
            {features.map((feature, index) => (
                <p className="plan-feature" key={index}>
                    {feature}
                </p>
            ))}
            <p className={ "plan-price " + color }>{price}</p>
            <a href={href}>
                <button className={ "btn-order " + type  }>
                    Order Now
                </button>
            </a>
        </div>
    );
};
export default ProductBox;