import React from 'react';
import '../css/company.css';
import Header from '../components/header';
import Footer from '../components/footer'; 
import BluefoxLogoFull from '../images/logo-full.png'
function CompanyPage() {
    return (
        <div>

            <Header />

            <section className="company">

                <div className="flex-about">

                    <div className="side-about">

                        <p className="title">About BlueFox Host</p>
                        <p className="subtitle">Here, we invite you to delve into the heart of our story, values, and mission.</p>

                        <p className="text-about">BlueFoxHost was created on <span className="bold">04/27/2020</span> with the goal of providing a secure and stable platform for their own use. Our team is a group of dedicated individuals who are dedicated to providing the best experience for our clients.</p>
                        <p className="text-about">We put customer satisfaction above all else. We are dedicated to providing the smoothest and most efficient experience for our users. Most of BlueFoxHost's services can be deployed within a few seconds.</p>
                        <p className="text-about">Shoutout to our <a className="link-about" href="/company/team">team</a> for their dedication and hard work.</p>
                    </div>
                    <div className="side-about center-about">
                        <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
                    </div>
                </div>

            </section>

            <Footer />

        </div>
    );
}

export default CompanyPage;
