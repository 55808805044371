import React from 'react';
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import '../css/legal.css'; 

const ServiceLevelAgreement = () => {
    return (
        <div>
            <Header />
            <section className="legal-container">
                <div className="terms">
                    <div className="head">
                        <h1 className="title">Service Level Agreement</h1>
                        <h3 className="subtitle">Last Updated: 27 January 2022</h3>
                    </div>
                    <h4 className="title">Introduction</h4>
                    <p>By using services rendered by BlueFoxHost, you automatically agree to our Service Level Agreement (herein referred to as “SLA”), including all the clauses listed within it. If you do not agree, you must cease use of the service immediately. We reserve the right to update or modify any part of the SLA, at any time, without prior notice. It is your responsibility to periodically review the SLA and update yourself with the changing provisions..</p>
                    <h4 className="title">Guarantee</h4>
                    <p>We guarantee, 99.9% uptime for all services rendered by BlueFoxHost. We do not guarantee that any service will be uninterrupted or error-free. If any of our physical machines fail to supply the necessary services to you for 15 consecutive minutes, the services affected will be covered by our SLA</p>
                    <h4 className="title">Claiming Compensation</h4>
                    <p>In order to apply for service outage compensation, you must create a support ticket on our billing system with the correlating account that which was affected by the outage. This ticket must be created within 3 days of the service outage in order to be granted compensation.</p>
                    <h4 className="title">Limitations</h4>
                    <p>BlueFoxHost's SLA does apply to affected services during the following circumstances <br /> While our technicians are either conducting maintenance on our services which results in temporary downtime. All maintenance will be announced on our Discord along with our billing system. Anything that is outside of BlueFoxHost which causes any sort of outage, our SLA will not apply to your affected services. If the client installs any sort of 3rd party software/script which causes downtime, once again, our SLA will not apply to your affected services. If your service is unavailable due to a DoS or a DDoS attack, our SLA will not apply to your affected services. Our systems are built to be able to withstand and filter some of the biggest DDoS attacks.</p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default ServiceLevelAgreement;
