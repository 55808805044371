import React from 'react';
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import '../css/legal.css'; 

const TermsAndConditions = () => {
    return (
        <div>
            <Header />
            <section className="legal-container">
                <div className="terms">
                    <div className="head">
                        <h1 className="title">Terms &amp; Conditions</h1>
                        <h3 className="subtitle">Last Updated: 5th Jan 2023</h3>
                    </div>
                    <h4 className="title">Introduction</h4>
                    <p>By using any of BlueFoxHost (“we”, “us”, “our”) services, including, the website, Discord server, billing, panel, and more, you (“client”, “customer”, “you”, “your”) we assume you accept these terms and conditions in full. Do not continue to use BlueFoxHost's website if you do not accept all of the terms and conditions stated on this page.</p>
                    <h4 className="title">Cookies</h4>
                    <p>We employ the use of cookies. By using BlueFoxHost's website you consent to the use of cookies in accordance with BlueFoxHost's privacy policy.<br /><br />Most of the modern day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising partners may also use cookies.</p>
                    <h4 className="title">Refunds</h4>
                    <p>If a service experiences any unexpected downtime within the first 24 hours, the client can request a full refund.<br></br>If your service is found guilty of any copyright infringements, trademark infringements or otherwise breaking American or International law. The service will be suspended, and if the infringement is not corrected a forced cancellation will be carried out with no refund.In all other cases, BlueFoxHost may not offer refunds. This includes early cancellations, credit added to your BlueFoxHost account and downgrades made to existing services.</p>
                    <h4 className="title">48 Hour Money-Back Policy</h4>
                    <p>Only Game servers are covered with 48 hour money back policy.<br></br>This only applies to new orders and does not apply if you, the client, did not create a cancellation in time. <br></br>To use this option, you need to open a support ticket and write the reason you wish to cancel and receive a refund. VPS and Dedicated servers are not covered by our 48 hour money-back policy.</p>
                    <h4 className="title">Chargebacks</h4>
                    <p>If a customer is caught filing a chargeback against BlueFoxHost to one of our payment processors it may result in full account termination, removal of any services without prior notice and fees may be issued to your account. If we are unable to process the payment for the fees from a payment method on file your debt may be sold to a creditor who may proceed to collect on it.</p>
                    <h4 className="title">Fair Use Policy</h4>
                    <p>BlueFoxHost has the reputation of being a cheap, budget host that does not cost you much money. Please keep in mind that we use actual servers, with actual limits. Uploading a mass amount of files can significantly downgrade our servers for everyone, including you. You are limited to the resources that you have paid for, and going over or abusing them will result in your account being terminated.</p>
                    <h4 className="title">User Conduct</h4>
                    <p>You agree to not use the service to:Any sort of illegal activity, including, but not limited to, hacking, bruteforce, mass emails, mass dm’s, raiding, ip spoofers, etc.Upload, post, transmit, or use content that you do not own without permission. Including, but not limited to, piracy, copyright, identity theft, etc. Operate and use DDoS/Botnet tools with the purpose of harming others, or anothers server, relationship, or otherwise objectionable.Use a form of mass email service.Such as spam emails, advertisement emails, etc. Use or operate anything that is keylogging, proxy service, irc/shell/shell(s) if any type. Interfere with or disrupt the service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service</p>
                    <h4 className="title">Nudity and Sexually Explicit Material</h4>
                    <p>By using our services, you agree to refrain from uploading, sharing, or otherwise distributing any content that contains nudity or sexually explicit material. Any violation of this policy may result in the termination of your account and access to our services.</p>
                    <h4 className="title">Advertisements</h4>
                    <p>Occasionally we may send you promotional material to your email address if you opted in to marketing emails. You can opt out at anytime by switching this setting off inside of your customer account.</p>
                    <h4 className="title">Accounts</h4>
                    <p>You must be at least 13 years of age to use BlueFoxHost. If you are under 13, you must have permission from your parent or guardian to use BlueFoxHost. <br></br>BlueFoxHost reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their absolute discretion.</p>
                    <h4 className="title">License</h4>
                    <p>Unless otherwise stated, BlueFoxHost and/or its licensors own the intellectual property rights for all material on BlueFoxHost. All intellectual property rights are reserved. You may view and/or print pages from https://bluefoxhost.com for your own personal use subject to restrictions set in these terms and conditions. You must not:Republish material from https://bluefoxhost.com<br></br>Sell, rent or sub-license material from https://bluefoxhost.com<br></br>Reproduce, duplicate or copy material from https://bluefoxhost.com<br></br>Redistribute content from BlueFoxHost (unless content is specifically made for redistribution).</p>
                    <h4 className="title">Disclaimer</h4>
                    <p>BlueFoxHost, under no circumstances should be held liable for data loss, downtime, delay, disconnection of services at anytime.<br></br>BlueFoxHost is not responsible for the actions taking place in our services themselves.<br></br>BlueFoxHost is not responsible for any damage, alterations, or actions taken by an account subuser. Subusers are added at the discretion of the customer.<br></br>BlueFoxHost is not liable for any changes outside of the initial server configuration. We reserve the right to refuse support for 3rd party software that is not included with the original setup.</p>
                    <h4 className="title">Removal of links from our website</h4>
                    <p>If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you. Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</p>
                    <h4 className="title">Content Liability</h4>
                    <p>We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                    <h4 className="title">Suspension and Termination</h4>
                    <p>We will suspend your service 3 days after the next due date of the invoice generated by the system if it is not paid.<br></br>We will terminate your service 5 days after the next due date if the invoice is not paid.<br></br>Additionally, we reserve the right to suspend or terminate your services upon any violation of these terms or in other cases deemed necessary for any reason whatsoever and at anytime without prior notice.</p>
                    <h4 className="title">Support</h4>
                    <p>We have a variety of ways that you can contact us this can be done by email, tickets and the live chat these are all displayed on our site. Any other support method is NOT considered official and you will not be guaranteed a response. Our support agents will do their utmost best to answer all requests in a reasonable time frame however this may vary depending on the complexity of the request and due to enquiry load. Using our support systems to transmit any material that harasses another person, profanity, and threats are strictly forbidden and may result in your account being limited. Our support agents can only provide support for BlueFoxHost services and only to the customer that purchased the service.</p>
                    <h4 className="title">Right to Modification</h4>
                    <p>We reserve the right to modify any part of these terms at anytime with or without prior notification. It is your responsibility to check our website regularly for updates. The continued use of the service constitutes acceptance of these modifications.<br></br> We reserve the right to modify service descriptions, quantity, pricing at anytime without prior notice. </p>
                    <h4 className="title">Promotions</h4>
                    <p>BlueFoxHost cannot guarantee that promotions and promo codes remain operational at all times.</p>
                    <h4 className="title">Business Hours</h4>
                    <p>Technical support is available 24/7/365 unless stated otherwise by our support team, however, tickets submitted on public holidays or during weekends may be delayed.<br></br>All tickets average a response time of a few hours after submission, however, tickets can take up to 48 hours in rare cases.<br></br>Other departments are available Monday through Friday (9 AM to 6 PM CDT).</p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default TermsAndConditions;
