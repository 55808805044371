import React from 'react';
import '../css/company.css';
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import BluefoxLogoFull from '../images/logo-full.png'
function HardwarePage() {
    return (
        <div>

            <Header />

            <section className="company">

                <div className="flex-about">

                    <div className="side-about">

                        <p className="title">Our Servers</p>
                        <p className="subtitle">Discover Cutting-Edge Technology and Exceptional Quality</p>

                        <p className="text-about">Please note that the hardware specifications may differ slightly from those listed on this page, although are always close in performance. The CPU Score is based on Passmark's single thread score.</p>
                    </div>
                    <div className="side-about center-about">
                        <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
                    </div>
                </div>

                <div className="div-hardware">
                    <p className="title">Hardware:</p>

                    <div className="terminal">
                        <div className="top-terminal">
                            <span><b>bluefoxhost@bluefoxhost-EX13-XF41: ~</b></span>
                        </div>
                        <div className="text-part">

                            <pre><span style={{ color: '#26A269' }}><b>bluefoxhost@bluefoxhost-EX13-XF41</b></span>:<span style={{ color: '#12488B' }}><b>~</b></span>$ hardware</pre>
                            <p className="faddad">[hardware] CPU: AMD Ryzen™ 5 3600 (Score 2571)</p>
                            <p>[hardware] RAM: DDR4 ECC Server Memory</p>
                            <p>[hardware] DISK: NVME SSD</p>
                            <p>[hardware] OS: Ubuntu v22.04</p>

                        </div>
                    </div>
                </div>

            </section>

            <Footer />

        </div>
    );
}

export default HardwarePage;
