import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/index.css'; 
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import GetStartedButton from '../components/GetStartedButton';
import LaptopImage from '../images/laptops.png';
import ManThinkingImage from '../images/thinking.png';
import FAQAccordion from "../components/FAQAccordion"; 

const faqItems = [
  {
    question: "How reliable is BlueFoxHost's uptime for my services?",
    answer: "At BlueFoxHost, we take uptime seriously and work tirelessly to ensure that your services stay online. Our team actively monitors and manages the servers to minimize any downtime and provide you with a great uptime experience. Here at BlueFoxHost, we guarantee, 99.9% uptime for all BlueFoxHost services."
  },
  {
    question: "What makes BlueFoxHost's panel different from others?",
    answer: "BlueFoxHost utilizes a customized version of the Pterodactyl Panel. Our team has invested significant effort in enhancing and adding numerous features to improve our clients' overall experience. You'll find a user-friendly and efficient panel designed to meet your hosting needs."
  },
  {
    question: "How does BlueFoxHost ensure transparency with its customers?",
    answer: "Transparency is one of our core values at BlueFoxHost. To maintain openness with our customers, we publicly display server resource usage data. This way, you can have a clear understanding of the performance and resource allocation of your hosting services."
  }
];

function FirstSection() {
    return (
        <section data-aos="fade-up" className="first">
            <p className="title-big">Powerful, Secure & Scalable Cloud Services</p>
            <p className="short-description">BlueFoxHost offers affordable pricing around the globe when it comes to all of our services. We provide premium support for each and every customer around the clock.</p>

            <select id="selected" className="select-service">
                <option value="none" disabled selected hidden className="select">Choose a service</option>
                <option value="minecraft" className="option">Minecraft Hosting</option>
                <option value="terraria" className="option">Terraria Hosting</option>
                <option value="bots" className="option">Discord Bot Hosting</option>
            </select>

            <GetStartedButton />

            <div className="center">
                <img className="laptops" src={LaptopImage} alt="Laptops" />
            </div>
        </section>
    );
}
function SecondSection() {
    return (
        <section className="second">
            <div data-aos="fade-right" className="second-div">
                <div className="flex-side">
                    <p className="title-second">The Most Advanced Hosting Provider</p>
                    <p className="description-second">BlueFoxHost consistently expands its services to cater to your evolving needs, staying ahead with innovative solutions for businesses and individuals alike. Count on us for comprehensive support in today's dynamic digital landscape.</p>
                </div>
                <div className="flex-side">
                    <div className="grid-layout">
                        <div className="section-grid">
                            <p className="number-section">01</p>
                            <p className="title-section">Great Uptime</p>
                            <p className="description-section">Here at BlueFoxHost, we guarantee, 99.9% uptime for all BlueFoxHost services.</p>
                        </div>
                        <div className="section-grid">
                            <p className="number-section">02</p>
                            <p className="title-section">Custom Panel</p>
                            <p className="description-section">We have modified Pterodactyl Panel and we have added and improved countless features in order to satisfy our client’s experience.</p>
                        </div>
                        <div className="section-grid">
                            <p className="number-section">03</p>
                            <p className="title-section">Transparency</p>
                            <p className="description-section">We try our absolute best to remain transparent with our customers which is why we display our server resource usage publicly.</p>
                        </div>
                        <div className="section-grid">
                            <p className="number-section">04</p>
                            <p className="title-section">Affordable Pricing</p>
                            <p className="description-section">Here at BlueFoxHost, we offer top notch support and powerful hardware at an affordable price.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
function ThirdSection() {
    return (
        <section data-aos="fade-down" className="third">
            <p className="small-title">Choose Your Game Service</p>
            <p className="small-description">Here at BlueFoxHost, you can deploy a server in a matter of minutes while getting top of the line support.</p>
            <div className="services-flex">
                <div className="section-service">
                    <p className="section-title">Terraria Hosting</p>
                    <p className="section-price">$0.00<span className="section-monthly">/Forever</span></p>
                    <div className="features">
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">3GB Memory</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">1x vCPU CPU</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">30GB Disk Space</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">Unlimited Bandwidth</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">1 MySQL Database</span>
                    </div>
                    <a href="/products/terraria">
                        <button className="section-order">Order Server Now</button>
                    </a>
                </div>
                <div className="section-service">
                    <p className="section-title">Minecraft Server Hosting</p>
                    <p className="section-price white">$0.00<span className="section-monthly">/Forever</span></p>
                    <div className="features">
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">8GB Memory</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">3x vCPU CPU</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">80GB Disk Space</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">Unlimited Bandwidth</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">1 MySQL Database</span>
                    </div>
                    <a href="/products/minecraft">
                        <button className="section-order blue">Order Server Now</button>
                    </a>
                </div>
                <div className="section-service">
                    <p className="section-title">Discord Bot Hosting</p>
                    <p className="section-price">$0.00<span className="section-monthly">/Forever</span></p>
                    <div className="features">
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">3GB Memory</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">2x vCPU CPU</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">15GB Disk Space</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">Unlimited Bandwidth</span>
                        <br />
                        <i className="checkmark fa-solid fa-check"></i>
                        <span className="section-feature">1 MySQL Database</span>
                    </div>
                    <a href="/products/bots">
                        <button className="section-order">Order Server Now</button>
                    </a>
                </div>
            </div>
            <div className="center top-20">
                <a href="/products/" className="section-other">see other plans...</a>
            </div>
        </section>
    );
}
function FourthSection() {
    return (
        <section className="fourth">
            <div className="line-fade"></div>
            <div data-aos="fade-left" className="second-div">
                <div className="flex-side scroller">
                    <p className="title-second">Top-rated.</p>
                    <p className="description-second">Explore the reasons behind our glowing reviews on Trustpilot as our satisfied customers vouch for the top-notch service and exceptional quality that distinguishes us from the rest.</p>
                    <i className="fa-solid fa-star star"></i>
                    <i className="star fa-solid fa-star"></i>
                    <i className="star fa-solid fa-star"></i>
                    <i className="star fa-solid fa-star"></i>
                    <i className="star fa-solid fa-star"></i>
                    <a target="_blank" href="https://www.trustpilot.com/review/bluefoxhost.com" className="check-yourself">Leave a review on TrustPilot</a>
                </div>
                <div className="flex-side zero">
                    <div className="flex-section-mover">
                        <div id="left" className="mover left">
                            <a target="_blank" href="https://trstp.lt/dSgtyd-91">
                                <div className="mover-section left-mover">
                                    <img className="mover-image" alt="Mover Avatar" src="https://user-images.trustpilot.com/64c2c935701fed001258269c/73x73.png" />
                                    <span className="mover-name">Matias Markelic</span>
                                    <p className="mover-text">This hosting service has been exceptional, consistently delivering top-notch...</p>
                                </div>
                            </a>
                            <a target="_blank" href="https://trstp.lt/PK8-LwHXr">
                                <div className="mover-section left-mover">
                                    <img className="mover-image" alt="Mover Avatar" src="https://user-images.trustpilot.com/60245216b2252d001ad5b33f/73x73.png" />
                                    <span className="mover-name">Svero</span>
                                    <p className="mover-text">This hosting service is absolutely amazing! They're the best out there...</p>
                                </div>
                            </a>
                            <a target="_blank" href="https://trstp.lt/lSc-QOP5Z">
                                <div className="mover-section left-mover">
                                    <img className="mover-image" alt="Mover Avatar" src="https://ui-avatars.com/api/background=2db0fe&color=fff?name=MM" />
                                    <span className="mover-name">Matias Markelic</span>
                                    <p className="mover-text">This hosting service has been exceptional, consistently delivering top-notch...</p>
                                </div>
                            </a>
                        </div>
                        <div id="right" className="mover right">
                            <a target="_blank" href="https://trstp.lt/tJwshy2pA">
                                <div className="mover-section">
                                    <img className="mover-image" alt="Mover Avatar" src="https://ui-avatars.com/api/background=2db0fe&color=fff?name=MA" />
                                    <span className="mover-name">Mostafa Askaf</span>
                                    <p className="mover-text">Amazing hosting with amazing owner! Everytime I have a problem the owner fix it...</p>
                                </div>
                            </a>
                            <a target="_blank" href="https://trstp.lt/V_7F2CXDb">
                                <div className="mover-section">
                                    <img className="mover-image" alt="Mover Avatar" src="https://user-images.trustpilot.com/60a7f95449f06e001accd1f3/73x73.png" />
                                    <span className="mover-name">Aanthro</span>
                                    <p className="mover-text">Getting a server is extremely easy, the staff are very helpful, the community...</p>
                                </div>
                            </a>
                            <a target="_blank" href="https://trstp.lt/jPWaY13ky">
                                <div className="mover-section">
                                    <img className="mover-image" alt="Mover Avatar" src="https://user-images.trustpilot.com/602546af5e26c2001a95ff9d/73x73.png" />
                                    <span className="mover-name">Mr. C. Soldier</span>
                                    <p className="mover-text">The Hosting service is great - It provides services for hosting games...</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line-fade"></div>
        </section>
    );
}

function FifthSection() {
    return (
        <section data-aos="fade-right" className="fifth">
            <p className="small-title">Frequently asked questions</p>
            <p className="small-description">Got a question? We have the answers.</p>
            <div className="second-div">
                <div className="flex-side center-img">
                    <img src={ManThinkingImage} alt="Man Thinking" className="man-thinking" />
                </div>
                <div className="flex-side large">
                    <FAQAccordion faqItems={faqItems} />
                </div>
            </div>
        </section>
    );
}

function SixthSection() {
    return (
        <section data-aos="fade-up" className="sixth">
            <p className="ready-text">Ready? <span className="lets-host">Let's host!</span></p>
            <p className="text-info">Here at BlueFoxHost, you can deploy a server in a matter of minutes while getting top of the line support.</p>
            <a href="/products">
                <button className="btn-start">
                    Order Now
                </button>
            </a>
        </section>
    );
}


function HomePage() {
    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'mobile',
        });
    }, []);

    return (
        <div>
            <Header />
            <FirstSection />
            <SecondSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
            <SixthSection />
            <Footer />
        </div>
    );
}

export default HomePage;
