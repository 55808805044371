import React from 'react';
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import '../css/products.css'; 
import ProductBox from '../components/ProductBox';
import MinecraftBlockImage from '../images/products/minecraft-block.webp'

const MinecraftPage = () => {
    return (
            <div>
                <Header />
                <section className="products">
                    <div className="product-box">
                        <div className="product-side">
                            <i className="controler fa-solid fa-gamepad"></i>
                            <span className="product-name-game">Minecraft Server Hosting</span>
                            <p className="product-title minecraft-text">Fast and reliable Minecraft hosting.</p>
                            <p className="product-description">Building in creative mode, collective fighting in survival and hardcore mode, or just mini-games. Minecraft. Almost everyone knows it and millions of people play it almost every day. With BlueFox you have control over your server and get all the features you need and more!</p>
                            <a href="https://billing.bluefoxhost.com/index.php?rp=/store/minecraft-hosting">
                                <button className="btn-product-box minecraft">
                                    Order Now
                                </button>
                            </a>
                        </div>
                        <div className="product-side-right">
                            <img className="product-image" src={MinecraftBlockImage} alt="Product Image" />
                        </div>
                    </div>
                    <div className="product-box-second">
                        <div className="products-boxes">
                        <ProductBox
                            type="minecraft"
                            icon="gamepad"
                            planName="MC-04"
                            gb="4GB"
                            features={[
                                "4GB DDR4",
                                "2x vCPUs",
                                "40GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=MC%204GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20MC%204GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        /> 
                        <ProductBox
                            type="minecraft"
                            icon="gamepad"
                            planName="MC-06"
                            gb="4GB"
                            features={[
                                "6GB DDR4",
                                "3x vCPUs",
                                "60GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=MC%206GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20MC%206GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        /> 
                        <ProductBox
                            type="minecraft"
                            icon="gamepad"
                            planName="MC-08"
                            gb="8GB"
                            features={[
                                "8GB DDR4",
                                "3x vCPUs",
                                "80GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=MC%208GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20MC%208GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        /> 
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
    );
};

export default MinecraftPage;
