import React from 'react';
import '../css/footer.css'; 
import BlueFoxLogo from '../images/logo-text.webp';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-flex">
                <div className="footer-side">
                    <img className="logo-footer" src={BlueFoxLogo} alt="Logo BlueFoxHosting" />
                    <p className="company-shortdesc">A new take on reliable and affordable hosting. BlueFoxHost provides stable services while maintaining efficient customer service.</p>
                </div>
                <div className="footer-side">
                    <p className="footer-title">Legal</p>
                    <a href="/terms" className="footer-section">Terms of Service</a>
                    <a href="/privacy" className="footer-section">Privacy Policy</a>
                    <a href="/sla" className="footer-section">Service Level Agreement</a>
                </div>
                <div className="footer-side">
                    <p className="footer-title">Other</p>
                    <a href="https://discord.gg/bluefox" className="footer-section">Discord Server</a>
                    <a href="https://www.trustpilot.com/review/bluefoxhost.com" className="footer-section">Trustpilot</a>
                    <a href="https://twitter.com/BlueFoxHost" className="footer-section">Twitter</a>
                </div>
            </div>
            <div className="line-footer"></div>
            <p className="footer-cr">&copy; {new Date().getFullYear()} BlueFox Host - All rights reserved</p>
        </footer>
    );
}

export default Footer;
