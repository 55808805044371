import React from 'react';
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import ProductBox from '../components/ProductBox';
import TerrariaTreeImage from '../images/products/terraria-tree.webp'

const TerrariaHosting = () => {
    return (
        <div>
            <Header />

            <section className="products">
                <div className="product-box">
                    <div className="product-side">
                        <i className="controler fa-solid fa-gamepad"></i>
                        <span className="product-name-game">Terraria Hosting</span>
                        <p className="product-title terraria-text">Fast and reliable Terraria hosting.</p>
                        <p className="product-description">Terraria is a 2D sandbox game with gameplay that revolves around exploration, building, crafting, combat, survival, and mining, playable in both single-player and multiplayer modes.</p>
                        <a href="https://billing.bluefoxhost.com/index.php?rp=/store/terraria-hosting">
                            <button className="btn-product-box terraria">
                                Order Now
                            </button>
                        </a>
                    </div>
                    <div className="product-side-right">
                        <img className="product-image" src={TerrariaTreeImage} alt="Product Image" />
                    </div>
                </div>
                <div className="product-box-second">
                    <div className="products-boxes">
                        <ProductBox
                            type="terraria"
                            icon="gamepad"
                            planName="TE-01"
                            gb="1GB"
                            features={[
                                "1GB DDR4",
                                "1x vCPUs",
                                "10GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=TE%201GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20TE%201GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        />
                        <ProductBox
                            type="terraria"
                            icon="gamepad"
                            planName="TE-02"
                            gb="2GB"
                            features={[
                                "2GB DDR4",
                                "1x vCPUs",
                                "20GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=TE%202GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20TE%202GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        />
                        <ProductBox
                            type="terraria"
                            icon="gamepad"
                            planName="TE-03"
                            gb="3GB"
                            features={[
                                "3GB DDR4",
                                "1x vCPUs",
                                "30GB NVMe Disk",
                                "Unlimited Bandwidth",
                                "1 MySQL Database"
                            ]}
                            price="$0.00 Forever"
                            href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=TE%203GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20TE%203GB%20service.%20My%20desired%20server%20location%20is%20Germany.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%5D%20with%20the%20chosen%20location."
                        />
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default TerrariaHosting;
