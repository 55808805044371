import React from 'react';
import '../css/products.css'; 
import Header from '../components/header'; 
import Footer from '../components/footer'; 
import ProductBox from '../components/ProductBox';
import DiscordLogoImage from '../images/products/discord-logo.webp'
function ProductsPage() {
    return (
        <div>

            <Header />

            <section className="products">

                <div className="product-box">

                    <div className="product-side">
                        <i className="controler fa-solid fa-robot"></i>
                        <span className="product-name-game">Discord Bot Hosting</span>
                        <p className="product-title">Fast and reliable Bot hosting.</p>
                        <p className="product-description">Discord bot hosting allows you to host your Discord bot 24/7. You have full access to the bot's files with access to our intuitive panel to control the bot.</p>

                        <a href="https://billing.bluefoxhost.com/index.php?rp=/store/discord-bot-hosting">
                            <button className="btn-product-box">
                                Order Now
                            </button>
                        </a>
                    </div>

                    <div className="product-side-right">
                        <img className="product-image" src={DiscordLogoImage} alt="Product Image" />
                    </div>

                </div>

                <div className="product-box-second">
                    <div className="products-boxes">
                    <ProductBox
                        icon="robot"
                        planName="BOT 1GB"
                        gb="1GB"
                        features={[
                            "1GB DDR4",
                            "1x vCPUs",
                            "5GB NVMe Disk",
                            "Unlimited Bandwidth",
                            "1 MySQL Database"
                        ]}
                        price="$0.00 Forever"
                        href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=BOT%201GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20BOT%201GB%20service.%20My%20preferred%20server%20location%20is%20%5BGermany%2FVirginia%5D.%20As%20for%20the%20programming%20language%2C%20I%20would%20like%20to%20use%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%2FVirginia%5D%20with%20the%20chosen%20location%20and%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D%20with%20the%20chosen%20language."
                    />
                    <ProductBox
                        icon="robot"
                        planName="BOT 2GB"
                        gb="2GB"
                        features={[
                            "2GB DDR4",
                            "2x vCPUs",
                            "10GB NVMe Disk",
                            "Unlimited Bandwidth",
                            "1 MySQL Database"
                        ]}
                        price="$0.00 Forever"
                        href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=BOT%202GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20BOT%202GB%20service.%20My%20preferred%20server%20location%20is%20%5BGermany%2FVirginia%5D.%20As%20for%20the%20programming%20language%2C%20I%20would%20like%20to%20use%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%2FVirginia%5D%20with%20the%20chosen%20location%20and%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D%20with%20the%20chosen%20language."
                    />
                    <ProductBox
                        icon="robot"
                        planName="BOT 3GB"
                        gb="3GB"
                        features={[
                            "3GB DDR4",
                            "2x vCPUs",
                            "15GB NVMe Disk",
                            "Unlimited Bandwidth",
                            "1 MySQL Database"
                        ]}
                        price="$0.00 Forever"
                        href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=BOT%203GB&message=Dear%20Support%2C%0A%0AI%20am%20interested%20in%20the%20BOT%203GB%20service.%20My%20preferred%20server%20location%20is%20%5BGermany%2FVirginia%5D.%20As%20for%20the%20programming%20language%2C%20I%20would%20like%20to%20use%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D.%0A%0A--%20Note%20--%0AReplace%20%5BGermany%2FVirginia%5D%20with%20the%20chosen%20location%20and%20%5BJava%2FJavaScript%2FPython%2FRuby%2FLua%2FGolang%5D%20with%20the%20chosen%20language."
                    />

                    </div>
                </div>

            </section>

            <Footer />

        </div>
    );
}

export default ProductsPage;
