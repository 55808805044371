import React, { useState } from "react";

function FAQAccordion({ faqItems }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleBoxClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      {faqItems.map((item, index) => (
        <div key={index} className="question-div" onClick={() => handleBoxClick(index)}>
          <p className="question-text">{item.question}</p>
          <i className={`caret-question transition fa-solid fa-chevron-down${activeIndex === index ? " active-caret" : ""}`}></i>
          <p className={`question-answer${activeIndex === index ? " active" : ""}`}>{item.answer}</p>
        </div>
      ))}
    </div>
  );
}

export default FAQAccordion;